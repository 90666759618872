import Stat from 'Shared/components/Stat'
import Boxes from 'Shared/components/Boxes'
import { parseISO, format } from 'date-fns'
import EmailPreview from 'Shared/components/EmailPreview'
import SendProgress from 'SendBlast/components/SendProgress'
import useEmailPreview from 'Shared/hooks/useEmailPreview'
import './EmailOverview.scoped.scss'
import SendSampleEmail from 'CampaignBuilder/components/Steps/SendSample/SendSampleEmail'
import restClient from 'Shared/hooks/restClient'
import { useEffect, useState } from 'react'

const ICONS = {
  'campaign type': <SvgIconsMail width={32} height={32} />,
  'target audience': <SvgIconsAudience width={32} height={32} />,
  'sent at': <SvgIconsOutgoing width={32} height={32} />,
  'sender name': <SvgIconsUsers width={32} height={32} />,
  workflow: <SvgIconsAutomations width={32} height={32} />,
  'subscription topic': <SvgIconsQuote width={32} height={32} />,
}

function NumberStats({ stats }) {
  return (
    <div className="number-stats">
      {stats.map((stat) => {
        return (
          <div className="number-stat" key={stat.label}>
            <p className="value">{stat.value}</p>
            <p className="label">{stat.label}</p>
            {stat.rawNumber && <p className="raw-number">{stat.rawNumber}</p>}
          </div>
        )
      })}
    </div>
  )
}

function EmailAttribute({ large, name, label, borderBottom = false, searchId = '' }) {
  return (
    <Stat
      primary={name}
      icon={ICONS[label]}
      borderBottom={borderBottom}
      size={large ? 'large' : 'normal'}
      label={label}
      searchId={searchId}
    />
  )
}

function calculateRate(total, number) {
  if (parseInt(total) === 0 || parseInt(number) === 0) return 0
  return Math.round((parseFloat(number) / parseFloat(total)) * 100)
}

const ZEROED_STATS = {
  emailsCount: 0,
  deliveredCount: 0,
  bouncedCount: 0,
  openedCount: 0,
  clickedCount: 0,
  unsubscribedCount: 0,
  helpfulCount: 0,
  notHelpfulCount: 0,
}

export default function EmailOverview() {
  const campaignId = useSel((s) => s.local.campaignId)
  const campaign = useSel((s) => s.campaign)
  const campaignBuilder = useSel((s) => s.campaignBuilder)
  const search = useSel((s) => s.campaign?.search)
  const stats = campaign?.stats || {}
  const emailBlastId = useSel((s) => s.local.emailBlastId)
  const emailBlast = useSel((s) => s.campaign?.emailBlast)
  const emailSender = emailBlast?.emailSender
  const sentAt = emailBlast?.sentAt
    ? format(parseISO(emailBlast.sentAt), 'dd/MM/yyyy')
    : ''
  const [html, subjectLine, loading] = useEmailPreview([], campaignId, emailBlastId)
  const showSendProgress = emailBlast && emailBlast.finishedSendingAt === null
  const [sendSampleModalOpen, setSendSampleModalOpen] = useState(false)
  const canSendSample = !!emailBlast?.emailSenderId && !!campaignBuilder?.previewTargetId
  const act = useAct()
  const dis = useDis()
  const [workflows, setWorkflows] = useState([])

  useEffect(() => {
    dis({ type: 'campaignBuilder/upsert', payload: { previewTargetId: search?.id } })
    act.campaign.upsert({
      id: campaignId,
      emailBlast: emailBlast,
    })
  }, [search, emailBlast])
  useEffect(() => {
    restClient
      .get(`/api/campaigns/${campaignId}/workflows`)
      .then(({ data }) => setWorkflows(data))
  }, [])

  const {
    emailsCount,
    deliveredCount,
    bouncedCount,
    openedCount,
    clickedCount,
    unsubscribedCount,
    helpfulCount,
    notHelpfulCount,
  } = (stats.emailsCount || 0) > 0 ? stats : ZEROED_STATS

  const outcomeStats = [
    {
      label: 'open rate',
      value: `${calculateRate(emailsCount, openedCount)}%`,
      rawNumber: `${openedCount.toLocaleString()} opens`,
    },
    {
      label: 'click rate',
      value: `${calculateRate(emailsCount, clickedCount)}%`,
      rawNumber: `${clickedCount.toLocaleString()} clicks`,
    },
    {
      label: 'unsubscribes',
      value: `${unsubscribedCount.toLocaleString()}`,
    },
    {
      label: 'bounce rate',
      value: `${calculateRate(emailsCount, bouncedCount)}%`,
      rawNumber: `${bouncedCount.toLocaleString()} bounced`,
    },
  ]

  if (campaign.subscriptionTopic !== 'default') {
    var subscriptionTopicLabel = window.ENV.AVAILABLE_SUBSCRIPTIONS.email.find(
      (sub) => sub.topic === campaign.subscriptionTopic
    )?.label
  }

  const showHelpfulStat = helpfulCount + notHelpfulCount > 0

  return (
    <div className="email-overview">
      <Boxes
        boxes={[
          <Stat
            icon={<SvgIconsPersonBlue width={96} height={96} />}
            borderBottom={false}
            size={'xl'}
            label={'recipients'}
            number={emailsCount}
          />,
          <div>
            <EmailAttribute name="Email blast" label="campaign type" borderBottom />
            <EmailAttribute
              name={search?.name}
              label="target audience"
              borderBottom={!!subscriptionTopicLabel}
              searchId={search?.id}
            />
            {subscriptionTopicLabel && (
              <EmailAttribute name={subscriptionTopicLabel} label="subscription topic" />
            )}
          </div>,
          <div>
            <EmailAttribute name={sentAt} label="sent at" borderBottom />
            <EmailAttribute name={emailSender?.name} label="sender name" borderBottom />
            {workflows?.map((workflow, i) => (
              <EmailAttribute
                key={workflow.id}
                name={workflow.name}
                label="automation"
                borderBottom={i < workflows.length - 1}
              />
            ))}
          </div>,
        ]}
        containerClass={'gutter-bottom'}
      />

      <Boxes
        boxes={[
          <div>
            <NumberStats stats={outcomeStats} />

            {showSendProgress && (
              <div className="margin-top double">
                <SendProgress
                  campaignId={campaignId}
                  leftAligned={true}
                  selfContained={true}
                  entityType="emailBlast"
                  entityId={emailBlastId}
                />
              </div>
            )}
          </div>,
          showHelpfulStat && (
            <Stat
              icon={<SvgIconsQuestion width={32} height={32} />}
              borderBottom={false}
              size={'normal'}
              label={`out of ${helpfulCount + notHelpfulCount} found this helpful`}
              primary={`${calculateRate(helpfulCount + notHelpfulCount, helpfulCount)}%`}
            />
          ),
        ].filter(Boolean)}
        sizes={showHelpfulStat ? ['two-thirds', 'third'] : ['full-width']}
      />

      {stats?.links && stats?.links.length > 0 && (
        <div className="box margin-top margin-bottom">
          <h2 className="no-margin-top">Links</h2>
          <ul>
            {stats.links.map(({ url, count }) => (
              <li key={url}>
                <strong>{count}</strong> - {url}
              </li>
            ))}
          </ul>
        </div>
      )}

      {html && (
        <div className="box margin-top preview-container">
          <div className="header">
            <h2 className="no-margin-top">Preview</h2>
            {canSendSample && (
              <div className="actions">
                <button
                  type="button"
                  className="button primary"
                  onClick={() => (canSendSample ? setSendSampleModalOpen(true) : null)}
                >
                  Send sample
                </button>
                <SendSampleEmail
                  open={sendSampleModalOpen}
                  onlyAllowEmail={true}
                  renderedBody={html}
                  close={() => setSendSampleModalOpen(false)}
                />
              </div>
            )}
          </div>

          <EmailPreview
            html={html}
            subjectLine={subjectLine}
            senderName={emailSender?.name}
            searchName={search?.name}
            inline={true}
            showHeader
            iframeStyle={{
              transform: 'scale(0.7)',
              width: '100%',
              border: '2px solid grey',
              padding: '10px',
            }}
          />
        </div>
      )}
    </div>
  )
}
